@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// // @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// // @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
// @include foundation-close-button;
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
// @include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// // @include foundation-progress-element;
// // @include foundation-meter-element;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

body {
  .parallax-mirror {
      z-index:1;
  }
}
hr {
  // color: $green;
  border-color: $yellow;
}
h1,h2,h3,h4,h5,h6 {
  color: white;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}
h1 {
  color: $dark-blue;
  text-align: center;
  font-weight: 400;
  font-size: 30px;
}
h2 {
  color: $dark-blue;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 0 0 1px $dark-blue;
}
.btn-round {
  // border: 5px;
  border-radius: 20px;
}
.btn-round-large {
  // border: 5px;
  border-radius: 30px;
}

.logo-soon {
  margin-top: 50%;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

/* Variables */

// Adjust width accordingly (use even #'s)
$logo-width: 264px;
// Reduce px value to increase space between logo and menu text
$logo-padding: $logo-width - 100px;
// Leave alone
$logo-margin: - ($logo-width / 2);

body {
  background-color: $white;
  @include breakpoint(medium) {
    padding-top: 42px;
  }
}
.logged-header {
  z-index: 1000;
  top: 150px;
  position: fixed;
  background-color: $warning-color;
  color: white;
  padding: 20px;

  a {
    float: right;
  }
}
.off-canvas-content {
  z-index: -101;
  min-height: 100vh;
  background: transparent;
}
.right-off-canvas-toggle {
  .menu {
    display: block;
    margin: 1.25rem .5rem;
  }
}
.first-bar,
.second-bar,
.third-bar {
	display: block;
	width: 25px;
	height: 3px;
  border-radius: 1px;
	background-color: $white;
	margin-bottom:6px;
	transition: transform 0.4s cubic-bezier(1, 0, 0, 1) 0, background-color 0.6s ease 0;
}
.third-bar {
  margin-bottom: 0px;
}

.first-bar, .third-bar {
	transform-origin: 100% 50%;
}

.second-bar {
	transition-property: opacity;
	transition-duration: 0.6s;
}

.first-bar-close {
	transform: rotate(-45deg);
	background-color: $white;
}

.second-bar-close {
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.1s;
}

.third-bar-close {
	transform: rotate(45deg);
	background-color: $white;
}
.off-canvas {
  min-height: 100%;
  background: darken($dark-blue, 1);
  background-image: url("/inc/themes/bleuetroyal/assets/img/mobile-menu-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  label {
    text-transform: uppercase;
    background-color: rgba($black, .1);
    padding: 5px 20px;
    color: $light-gray;
    font-size: .9em;
    margin-bottom: 10px;
  }
  li {
    color: white;
    font-size: 1.2em;
    font-weight: 200 !important;
    i {
      margin-left: 20px;
      margin-right: 10px;
      vertical-align: middle;
    }
    a {
      color: white;
      font-size: 1.8em;
      font-weight: 200 !important;

      &:nth-child(2n) {
        display: inline;
        text-align: left;
        padding: 0;
        font-size: 1em;
      }
    }
  }
}

$slider_height_small: 350px;
$slider_height_medium: 500px;
$slider_height_large: 600px;

// Orbit slider

.orbit {
  height: $slider_height_large;
  margin-bottom: 30px;
  ul.orbit-container {
    display: block;
    height: $slider_height_large;
  }
  .orbit-slide {
    position: relative;
    display: block;
    width: 100%;
    height: $slider_height_large;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
.feat-text {
  font-family: 'Open Sans', sans-serif;
  position: relative;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 200!important;
  text-align: center;
  padding: 20px 10px 40px;

  .lead-text {
    font-family: 'Ubuntu', sans-serif;
    position: relative;
    text-transform: none;
    margin-bottom: 20px;
  }
  p {
    text-align: center;
  }
}
// .featured {
//   text-align: center;
//   color: $dark-blue;
//   margin-bottom: 50px;
//
//   .round {
//     width: 160px;
//     height: 160px;
//     margin: 20px auto;
//     // border: $dark-blue solid 2px;
//     // border-radius: 50%;
//   }
//   p {
//     color: $dark-blue;
//     font-size: 16px;
//   }
// }

.home-about {
  z-index: 2;
  padding: 70px 20px 40px;
  min-height: 400px;
  background: transparent;
  font-size: 1.3em;
  color: white;
  text-shadow: 0 0 1px $dark-blue;

  h2 {
    color: $white;
    margin-bottom: 30px;
    @include breakpoint(small only) {
      margin-top: 30px;
    }
  }

  p {
    vertical-align: middle;
    line-height: 28px;
    font-weight: 300;
  }
  .button {
    margin-top: 20px;
    font-weight: 400;
  }
}
.top-banner {
  height: 240px;
  margin-bottom: 50px;
  padding-top: 150px;
  background-image: url("/inc/themes/bleuetroyal/assets/img/blur-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include breakpoint(small only) {
    height: 180px;
    padding-top: 120px;
    margin-bottom: 40px;
  }

  h2 {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }
}
.bleuet-description {
  padding: 80px 0 40px;
  @include breakpoint(small only) {
    text-align: center;
  }
  @include breakpoint(medium only) {
    text-align: center;
  }

  p {
    color: darken($dark-gray,35);
    font-family: 'Open Sans', sans-serif;
    font-weight: 300!important;
    text-align: justify;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    @include breakpoint(medium) {
      margin-top: 40px;
      text-align: left;
    }
  }
}
// .home-contact {
//   margin-top: 30px;
//   margin-bottom: 50px;
//   padding-top: 40px;
//   p {
//     font-size: 2em;
//     font-family: 'Open Sans', sans-serif;
//     font-weight: 200 !important;
//   }
// }
// Our products section
.product-feat-text {
  @extend .feat-text;
  padding: 10px;
}
.product-description {
  margin-top: 40px;
  margin-bottom: 40px;
  h2 {
    text-align: left;
    margin-top: 50px;
    font-size: 16px;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  p {
    color: darken($dark-gray,45);
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 300!important;
    text-align: justify;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  .product-container {
    margin-bottom: 50px;
  }
  .round-frais,.round-congeles,.round-decoration {
    width: 290px;
    height: 290px;
    border-radius: 50%;
    // border: 10px solid ;
    box-shadow: 0px 0px 8px rgba($dark-blue,.5);
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint(medium only) {
      width: 200px;
      height: 200px;
    }
  }
  .round-frais {
    background-image: url("/inc/themes/bleuetroyal/assets/img/bleuets-frais.jpg");
  }
  .round-congeles {
    background-image: url("/inc/themes/bleuetroyal/assets/img/bleuets-congeles.jpg");
  }
  .round-decoration {
    background-image: url("/inc/themes/bleuetroyal/assets/img/bleuets-decoration.jpg");
  }

  &:nth-child(1) {
    background-color: blue;
  }
}
// les bleuets section

#w_slider {
  position: relative;
  padding: 0px;
  background-color: $light-gray;
  margin: 0 auto 30px;

  .slide_item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    list-style: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .active {
    z-index:10;
  }
  .last_active {
    z-index: 9;
  }
}
.rectangular_slider {
  width: 100%;
  height: 400px;

  @include breakpoint(small only) {
    height: 250px;
  }
  @include breakpoint(medium only) {
    // width: 330px;
    // height: 330px;
  }
}
.round_slider {
  $sqSize: 400px;
  border-radius: 50%;
  height: $sqSize;
  width: $sqSize;

  @include breakpoint(small only) {
    width: 300px;
    height: 300px;
  }
  @include breakpoint(medium only) {
    width: 330px;
    height: 330px;
  }
}
.notre_engagement {
  @include breakpoint(small only) {
    text-align: left;
  }
  @include breakpoint(medium only) {
    text-align: left;
  }
  p {
    color: darken($dark-gray,35);
    font-family: 'Open Sans', sans-serif;
    font-weight: 300!important;
    text-align: justify;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  h2 {
    margin-top: 20px;
    font-size: 16px;
    @include breakpoint(large) {
      margin-top: 40px;
    }
  }
}
// Nous Joindre
.map {
  margin-top: -50px;
  margin-bottom: 40px;
  height: 400px;
  @include breakpoint(small only) {
    height: 300px;
  }
}
.nous-joindre {
  padding-top: 30px;
  h4,h5,h6 {
    color: $dark-blue;
    margin-bottom: 0;
  }
  p {
    margin-top: 0px;
    margin-bottom: 15px;
  }
}
.footer-contact {
  margin-top: 30px;
  padding-top: 40px;
  padding-bottom: 30px;
  margin-top: 20px;
  background-color: $light-gray;
  p {
    font-size: 1.4em;
    color: darken($dark-gray, 20);;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300 !important;
    line-height: 1.45em;
  }
  h4 {
    color: $dark-blue;
  }
}
.page-contact {
  margin-top: 30px;
  padding: 40px 0 20px;
  margin-top: 20px;
  background-color: $light-gray;
  p {
    font-size: 2em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200 !important;
  }
}

// Footer
footer {
  position: relative;
  background-color: darken($dark-blue, 4);
  min-height: 340px;
  color: rgba($white, .5);
  padding: 20px 0;
  .logo-wrapper {
    position: relative;

    .logo {
      width: 266px;
      height:  191px;
      position: absolute;
      background-image: url('/inc/themes/bleuetroyal/assets/img/logo.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      left: 50%;
      right: 50%;
      top: 30px;
      margin-left: $logo-margin;
      @include breakpoint(retina) {
        background-image: url('/inc/themes/bleuetroyal/assets/img/logo@2x.png');
      }

      @include breakpoint(medium) {
        width: $logo-width;
      }
      @include breakpoint(large) {
        width: $logo-width;
      }
      @include breakpoint(830px down) {
        background-size: 100%;
      }
    }
  }


  .logo-footer {
    a {
      position: absolute;
      left: 0;
      right: 50%;
      vertical-align: middle;
      margin: 0 auto;
    }
  }

  div {
    span {
      text-align: center;
      background-color: darken($dark-blue, 2);
      border-radius: 50%;
      padding-bottom: 5px;
      i {
        color: rgba($white, .7);
        font-weight: 200!important;
        font-size: 3em;
      }
    }
    h6 {
      color: rgba($white, .7);
      font-weight: 600!important;
    }
    &:nth-child(2) {
      border-left: 1px solid darken($dark-blue, 5);

      @include breakpoint(small only) {
        border-left: 0;
        border-top: 1px solid darken($dark-blue, 5);
        // margin-bottom: 10px;
        margin-top: 10px;
      }
    }
    &:nth-child(3) {
      border-left: 1px solid darken($dark-blue, 5);

      @include breakpoint(small only) {
        border-left: 0;
        border-top: 1px solid darken($dark-blue, 5);
        margin-bottom: 30px;
      }
    }
    .menu li > a {
      color: rgba($white, .5);
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .foot {
    position: absolute;
    padding-bottom: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 0;
    line-height: 10px;
    background-color: darken($dark-blue, 5);
  }
}

@include breakpoint(small only) {
  .top-bar, .top-bar ul {
    background-color: white;
  }
  .title-bar {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: #094865;
    /* Fall-back for browsers that don't support rgba */
    background: rgba(9, 72, 101, 0.75);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .logo img {
      width: 200px;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-top: 5px;
      margin-left: -100px;
    }
  }
  .menu {
    a{
      margin-bottom: 10px;
    }
  }
  .off-canvas-content {
    box-shadow: 0px 3px 6px rgba(black, .16);
  }
  .orbit {
    height: $slider_height_small;
    ul.orbit-container {
      height: $slider_height_small;
    }
    .orbit-slide {
      height: $slider_height_small;
    }
  }
  .home-about {
    min-height: 300px;
    padding: 10px 0;

    .about-text {
      margin-top: 40px;
    }
  }
}

@include breakpoint(medium only) {
  .orbit {
    height: $slider_height_medium;
    ul.orbit-container {
      height: $slider_height_medium;
    }
    .orbit-slide {
      height: $slider_height_medium;
    }
  }
}
/* Medium-Up */
@include breakpoint(medium up) {
  .top-nav-bar {
    position: fixed;
    top: 0;
    z-index: 999;
    height: 42px;
    width: 100%;
    background-color: #F2F2F2;
    color: $dark-blue;
    font-size: 16px;
    font-style: italic;
    line-height: 40px;
    text-align: center;

    @include breakpoint(1015px down) {
      font-size: 15px;
    }
    @include breakpoint(920px down) {
      font-size: 14px;
    }
    @include breakpoint(775px down) {
      font-size: 12px;
    }
    @include breakpoint(685px down) {
      font-size: 11px;
      // height: 34px;
      // line-height: 32px;
    }

    // Right part
    .top-nav-bar-right {
      padding-left: $logo-padding;
      @include breakpoint(1084px down) {
        padding-left: $logo-padding/2;
      }
      @include breakpoint(830px down) {
        padding-left: $logo-padding/3;
      }
    }
    // Left part
    .top-nav-bar-left {
      padding-right: $logo-padding;
      @include breakpoint(1084px down) {
        padding-right: $logo-padding/2;
      }
      @include breakpoint(830px down) {
        padding-right: $logo-padding/3;
      }
    }
    span.days_untill {
      display: table-cell;
      vertical-align: middle;
      float: right;
      @include breakpoint(1084px down) {
        float: left;
      }
      i{
        vertical-align: middle;
      }
    }
    span.top-info {
      display: table-cell;
      vertical-align: middle;
      float: left;

      i{
        vertical-align: middle;
      }
      &:first-child {
        @include breakpoint(1015px down) {

        }
        @include breakpoint(920px down) {

        }
        @include breakpoint(775px down) {

        }
        @include breakpoint(1084px down) {
          margin-left: 35px;
        }
        @include breakpoint(930 down) {
          margin-left: 30px;
        }
        @include breakpoint(880 down) {
          margin-left: 15px;
        }
        @include breakpoint(846 down) {
          margin-left: 7px;
        }
        @include breakpoint(790 down) {
          margin-left: 5px;
        }
        @include breakpoint(780 down) {
          margin-left: 1px;
        }
        @include breakpoint(775 down) {
          margin-left: 10px;
        }
        @include breakpoint(774 down) {
          margin-left: 15px;
        }
        @include breakpoint(715px down) {
          margin-left: 0px;
        }
        @include breakpoint(685px down) {
            margin-left: -3px;
        }

      }
      &:nth-child(2) {
        margin-left: 15px;
        margin-right: 15px;
        @include breakpoint(1084px down) {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      button {
        font-size: .9em;
        margin-top: 7px;
        padding: 5px 7px;
        background-color: $dark-blue;
      }
    }

  }
  .top-bar {
    position: fixed;
    z-index: 1000;
    top: 42px;
    left: 0;
    right: 0;
    height: 105px;
    background: rgb(9, 72, 101); /* Fall-back for browsers that don't support rgba */
    background: rgba(9, 72, 101, .75);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

    .menu {
      .active a{
        color: $yellow;
        background: none;
      }
      li {
        .active a{
          color: $yellow;
          background: none;
        }
        a {
          display: table-cell;;
          color: $white;
          height: 90px;
          font-size: 1.5em;
          font-weight: 200;
          text-align: center;
          vertical-align: middle;
          &:hover {
            color: $yellow;
          }
        }
      }
    }
    .logo-wrapper {
      position: relative;

      .logo {
        width: 266px;
        height:  191px;
        position: absolute;
        background-image: url('/inc/themes/bleuetroyal/assets/img/logo.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        left: 50%;
        right: 50%;
        top: -50px;
        margin-left: $logo-margin;
        @include breakpoint(retina) {
          background-image: url('/inc/themes/bleuetroyal/assets/img/logo@2x.png');
        }

        @include breakpoint(medium) {
          width: $logo-width;
        }
        @include breakpoint(large) {
          width: $logo-width;
        }
        @include breakpoint(830px down) {
          top: -60px;
          margin-left: -90px;
          width: 180px;
          background-size: 100%;
        }
      }
    }

    // Right part
    .top-bar-right {
      width: 50%;
      padding-left: $logo-padding;

      ul {
        float: left;
      }
    }

    // Left part
    .top-bar-left {
      width:  50%;
      padding-right: $logo-padding;

      ul {
        float: right;
      }
    }
  }
}
@include breakpoint(large only) {

}
@include breakpoint(959px down) {
  .top-bar .menu li a {
    font-size: 1.3em;
  }
}
@include breakpoint(899px down) {
  .top-bar .menu li a {
    font-size: 1.1em;
  }
}
@include breakpoint(830px down) {
  .top-bar {
    height: 80px;

    .menu {
      li {
        a {
          height: 65px;
        }
      }
    }

    // Right part
    .top-bar-right {
      padding-left: 85px;
    }
    // Left part
    .top-bar-left {
      padding-right: 85px;
    }
  }
}

// animations
.animate-fade {
  @include mui-animation(fade);
  animation-duration: 1s;
}
.animate-fade-slide {
  @include mui-animation(fade, slide($direction: left));
  animation-duration: 1s;
}
.animate-fade-slide-up {
  @include mui-animation(fade, slide);
  animation-duration: 1s;
}
